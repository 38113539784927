import Link from 'next/link';
import { Box, Flex, Heading, ListIcon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import useT from '@/lib/t';
import FullPage from '@/src/components/UI/FullPage';
import ContactPrimary from '@/components/ContactPrimary';
import { useLayoutContext } from '../src/context/LayoutContext';

export default function Custom404() {
  const c = useT('share');
  const { headerHeight } = useLayoutContext();

  return (
    <FullPage hasPadding={false} title={c('backHome')}>
      <Flex
        h='calc(100vh - 260px)'
        justify='center'
        align='center'
        className={'px-4'}
        paddingTop={`${headerHeight + 16}px`}
      >
        <Flex direction='column'>
          <Heading as='h2'>{c('404.title')}</Heading>
          <Text as='p' color='gold' my={4}>
            {c('404.description')}
          </Text>
          <UnorderedList spacing={3} listStyleType='none'>
            <ListItem>
              <ListIcon as={ChevronRightIcon} />
              <Link href='/'>{c('nav.index')}</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronRightIcon} />
              <Link href='/buy'>{c('nav.buy')}</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronRightIcon} />
              <Link href='/agent'>{c('nav.agent')}</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronRightIcon} />
              <Link href='/abroad'>{c('nav.abroad')}</Link>
            </ListItem>
          </UnorderedList>
          <Box mt={16}>
            <ContactPrimary label={c('404.contact')} />
          </Box>
        </Flex>
      </Flex>
    </FullPage>
  );
}

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
