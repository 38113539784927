import { ReactElement, ReactNode } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';

type Props = {
  children: ReactNode;
  shouldWidthFit?: boolean;
};

export default function PhoneContact({ children, shouldWidthFit, ...props }: Props & ButtonProps): ReactElement {
  return (
    <Button
      leftIcon={<PhoneIcon />}
      variant='secondary'
      {...props}
      w={{ base: '100%', sm: shouldWidthFit ? 'fit-content' : '304px' }}
    >
      {children}
    </Button>
  );
}
