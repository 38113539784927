import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import PhoneContact from '@/src/components/UI/PhoneContact';
import { LineEventTypes, sendLineTagEvent } from '@/utils/sendLineTagEvent';

export default function ContactPrimary({ label, shouldWidthFit }: { label: string; shouldWidthFit?: boolean }) {
  const handleContact = useCallback(() => {
    window.open(`tel:${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`);
    sendLineTagEvent(LineEventTypes.phone_call);
  }, []);

  return (
    <Box alignSelf={'center'} bgColor={'transparent'}>
      <PhoneContact onClick={handleContact} shouldWidthFit={shouldWidthFit}>
        <span className={'w-full block truncate'}>{`${label} ${process.env.NEXT_PUBLIC_FUNWOO_TAIWAN_CELLPHONE}`}</span>
      </PhoneContact>
    </Box>
  );
}
